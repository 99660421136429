/* Source: https://css-tricks.com/box-sizing/ */
html {
    box-sizing: border-box;
    user-select: none;
}
*,
*:before,
*:after {
    box-sizing: inherit;
    user-select: inherit;
}

body,
#app {
    display: block;
    width: 100vw;
    height: 100vh;
    margin: 0;
    overflow: hidden;
}

button {
    backface-visibility: hidden;
    cursor: pointer;
}
