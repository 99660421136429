html {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
}

*, :before, :after {
  box-sizing: inherit;
  user-select: inherit;
}

body, #app {
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: block;
  overflow: hidden;
}

button {
  backface-visibility: hidden;
  cursor: pointer;
}

/*# sourceMappingURL=index.6625eb8e.css.map */
